<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ action }} Password
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            Please provide your new password
          </p>
        </v-card-text>

        <!-- reset form -->
        <v-card-text>
          <v-form
            ref="form"
            method="post"
            action="/"
            lazy-validation
            @submit.prevent="reset()"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-text-field
                  v-model="form.password"
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Password"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :rules="passwordRules"
                  required
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
                <small
                  v-show="form.errors.has('password')"
                  class="validation-error"
                >{{
                  form.errors.get('password')
                }}</small>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.password_confirmation"
                  type="password"
                  outlined
                  label="Confirm password"
                  placeholder="············"
                  hide-details
                  :rules="passwordRules.concat(passwordConfirmationRule)"
                  required
                ></v-text-field>
                <small
                  v-show="form.errors.has('password_confirmation')"
                  class="validation-error"
                >{{
                  form.errors.get('password_confirmation')
                }}</small>
              </v-col>
            </v-row>
            <br>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <div class="d-flex align-center justify-space-between flex-wrap">
                <!-- reset link -->
                <router-link
                  :to="{ name: 'reset'}"
                >
                  Back to Login
                </router-link>
              </div>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      alt="background-img"
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Form from 'vform'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      passwordRules: [v => !!v || 'Password is required', v => (v || '').length >= 8 || 'Must be at least 8 digits'],
      form: new Form({
        email: this.$route.query.email,
        password: '',
        password_confirmation: '',
        token: this.$route.params.token,
      }),
      action: this.titleCase(this.$route.query.action),
    }
  },
  setup() {
    const isPasswordVisible = ref(false)

    return {
      isPasswordVisible,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.form.password === this.form.password_confirmation || 'Password must match'
    },
  },
  methods: {
    ...mapActions(['authenticateUser']),
    reset() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('auth/admin/password/reset')
          .then(response => {
            this.$notification.success(response.data.message)
            this.$router.push({ name: 'login' })
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    titleCase(string) {
      return string[0].toUpperCase() + string.slice(1).toLowerCase()
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
